<template>
  <v-card
    class="mx-auto" elevation="6"
    :width="$vuetify.breakpoint.mobile ? '80%' : '40%'">
    <ProfileHead :user="user"></ProfileHead>
    <div>
      <v-row justify="center" v-if="showSubscription && isCollaborator">
        <v-btn v-if="isJoined" :loading="isLoading"
          @click="joinOrLeave('leave')">
          Leave</v-btn>
        <v-btn v-else @click="joinOrLeave('join')"  :loading="isLoading"
            class="primary">Join</v-btn>
      </v-row>
      <v-list>
        <v-divider inset></v-divider>
        <v-list-item>
          <v-list-item-content>
          <v-list-item-title>
            <span class="d-flex justify-center text-subtitle-2 text-lowercase pb-2">
              {{user.email}}
            </span>
          </v-list-item-title>
          <v-list-item-subtitle v-ripple @click="openMail" v-if="isCharity">
            <span class="grey--text d-flex justify-center">
              <v-icon color="primary">mdi-email-send-outline</v-icon>
              <span class="ml-2 mt-1">Send Email</span>
            </span>
          </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>
        <v-list-item v-if="isCharity">
          <v-list-item-content>
          <v-list-item-title v-ripple @click="phoneCall"
          class="d-flex justify-center text-subtitle-2 text-lowercase pb-2">
            <span>
            <v-icon class="mx-2" color="primary">mdi-phone-classic</v-icon>
              {{user.phoneNumber | phoneFilter}}
            </span>
          </v-list-item-title>
          <v-list-item-subtitle>
            <v-row justify="center" class="mt-1" v-ripple @click="whatsapp">
              <span>
              <v-icon color="primary" class="mr-3">mdi-whatsapp</v-icon>
              WhatsApp
              </span>
            </v-row>
          </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset v-if="isCharity"></v-divider>
        <v-list-item v-if="user.bio && user.bio !== ''">
          <v-list-item-content>
            <v-list-item-title>
              <div class="d-flex justify-center">
                <div  class="text-center" style="white-space: pre-wrap;">
                  <p v-html="formattedBio"></p></div>
              </div>
            </v-list-item-title>
            <v-list-item-subtitle>
              <span class="grey--text d-flex justify-center">
                <v-icon :medium="$vuetify.breakpoint.mobile" color="primary" >mdi-bio</v-icon>
                <span class="ml-2 mt-1">About {{user.group|groupFilter}}</span>
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset class="mb-7"></v-divider>
        <v-row justify="center"  v-if="differentApp && isCharity">
          <v-btn class= "mt-7" color="primary"
            @click="viewApp">View App</v-btn>
        </v-row>
        <v-row justify="center" v-if="allowShareApi">
          <v-btn class= "my-4" @click="webShare">
            <v-icon color="primary" class="mr-2">mdi-share-variant-outline</v-icon>
            Share this</v-btn>
        </v-row>
        <v-row justify="center">
        <div class="fb-share-button mb-7" :data-href="currentPage"
          data-layout="button" data-size="large">
          <v-btn> <v-icon class="mr-2" color="blue">mdi-facebook</v-icon>
          <a target="_blank"
          :href="facebookHref" style="text-decoration:none;">Share</a>
          </v-btn>
        </div>
        </v-row>
      </v-list>
    </div>
  </v-card>
</template>

<script>
/**
 * A profile component that displays charity account including all its detials
 */
import Vue from 'vue'
import ProfileHead from '@/components/account/ProfileHead.vue'
import { mapActions } from 'vuex'
export default {
  name: 'Profile',
  props: ['user', 'showSubscription', 'isJoined'],
  components: { ProfileHead },
  data () {
    return {
      isLoading: false,
      /**
       * Standard of Open Graph of FB
       * Reference: https://developers.facebook.com/docs/plugins/share-button/
       * */
      openGraph: [
        {
          property: 'og:url',
          content: window.location.href
        },
        {
          property: 'og:title',
          content: this.user.name
        },
        {
          property: 'og:description',
          content: this.user.bio
        },
        {
          property: 'og:image',
          content: this.user.picture
        },
        {
          property: 'og:type',
          content: 'Website'
        }
      ]
    }
  },
  computed: {
    differentApp () {
      // console.log('dif', Vue.$pathConverter.getFirstSegment() !== this.user.email)
      return Vue.$pathConverter.getFirstSegment() !== this.user.email
    },
    isCollaborator () {
      return Vue.$pathConverter.getFirstSegment() !== Vue.$keycloak.tokenParsed.email
    },
    allowShareApi () {
      return 'share' in navigator
    },
    isCharity () {
      return this.user.group === 'charity'
    },
    /**
     * Hyperlink Regex is taken from
     *  Reference: https://www.geeksforgeeks.org/how-to-validate-url-using-regular-expression-in-javascript/
     */
    formattedBio () {
      const hyperlinkRegex = /[-a-zA-Z0-9@:%_\\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\\+.~#?&//=]*)?/gi
      const result = this.user.bio.replaceAll(hyperlinkRegex, (hyperlink) => {
        return `<a href="${hyperlink}">${hyperlink}</a>`
      })
      return result
    },
    currentPage () {
      return window.location.href
    },
    facebookHref () {
      const url = window.location.href
      return 'https://www.facebook.com/sharer/sharer.php' +
      `?u=${url}`
    }
  },
  methods: {
    openMail () {
      window.location.href = `mailto:${this.user.email}`
    },
    phoneCall () {
      window.location.href = (`tel:+${this.user.phoneNumber}`)
    },
    whatsapp () {
      window.open(`https://wa.me/${this.user.phoneNumber}`)
    },
    viewApp () {
      const email = this.user.email
      window.location.href = `${window.location.origin}/${email}`
    },
    webShare () {
      if (navigator.share) {
        if (this.isCharity) {
          navigator.share({
            title: `${this.user.name} @ Charity`,
            url: `${window.location.origin}/${Vue.$pathConverter.getFirstSegment()}`
          })
        } else {
          navigator.share({
            title: `${this.user.name} @ Giver`,
            url: `${window.location.href}`
          })
        }
      } else {
        console.error('No Share APi')
      }
    },
    ...mapActions('userStore', ['joinCharity', 'leaveCharity']),
    joinOrLeave (action) {
      this.isLoading = true
      // console.log(action, this.isLoading)
      if (action === 'join') {
        this.joinCharity(this.user).finally(() => {
          this.isLoading = false
          // console.log(this.isLoading)
        })
      } else {
        this.leaveCharity(this.user).finally(() => {
          this.isLoading = false
          // console.log(this.isLoading)
        })
      }
    }
  },
  filters: {
    phoneFilter: (value) => {
      if (value !== undefined) {
        return `+${value.substring(0, 2)} ${value.substring(2)}`
      } else {
        return value
      }
    },
    groupFilter: (value) => {
      if (value === 'charity') {
        return 'The CHARITY'
      } else {
        return 'ME'
      }
    }
  },
  created () {
    this.openGraph.forEach((tag) => {
      const meta = document.createElement('meta')
      meta.setAttribute('property', tag.property)
      meta.content = tag.content
    })
  }
}
</script>
