<template>
  <div v-if="isLoaded">
    <Profile :user='charityOfApp' :showSubscription="isJoined!==null && loginConfirm"
      :isJoined="isJoined"></Profile>
  </div>
  <div v-else>
    <v-skeleton-loader type="card-avatar, list-item"  class = "mx-auto my-10"
      max-height="50vh" max-width="60vw"></v-skeleton-loader>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import Profile from '@/components/account/Profile.vue'
export default {
  components: {
    Profile
  },
  data () {
    return {
      isLoaded: false,
      isJoined: null
    }
  },
  computed: {
    ...mapGetters('appStore', ['charityOfApp']),
    ...mapGetters('userStore', ['registeredCharities', 'loginConfirm'])
  },
  watch: {
    /**
     * This function is triggered when there is a change to charityOfApp.
     *  This is used to tackle the issue of network speed.
     * If user's network is not fast enough and the page is loaded before
     *  the charity of the App (function in /router/index.js) is loaded,
     *  then this function will be triggered after the data is loaded to update.
     * When this component is first loaded, watch function will not be triggered but the created function
     * If a charity is found, it is loaded. If not, the user is redirected to error page.
     */
    charityOfApp (newValue) {
      // console.log('CharityAppHomeView: found a new ', newValue)
      if (!('email' in newValue)) {
        const firstSeg = Vue.$pathConverter.getFirstSegment()
        console.error(`Opps! Loading CharityAppHomeView fails due to unfound charity. 
          Route to error page of ${firstSeg}`)
        this.$router.push({
          name: 'Error',
          params: { root: firstSeg }
        })
      } else {
        this.isLoaded = true
      }
    },
    registeredCharities: {
      deep: true,
      handler (newRegisteredCharities) {
        this.updateIsJoined(newRegisteredCharities)
      }
    },
    loginConfirm (newloginConfirm) {
      if (newloginConfirm) {
        this.updateSubscription()
      }
    }
  },
  methods: {
    ...mapActions('userStore', ['requestRegisteredCharities']),
    updateSubscription () {
      this.requestRegisteredCharities().then(() => {
        this.updateIsJoined(this.registeredCharities)
      }).catch((err) => {
        if (err.response.status === 404) {
          console.error('No charity has been subscribed & registered. Turn JOIN option on')
          this.isJoined = false
        }
      })
    },
    updateIsJoined (registeredCharities) {
      // console.log(registeredCharities)
      const charity = registeredCharities.find((charity) => {
        return charity.email === this.charityOfApp.email
      })
      if (!charity) {
        this.isJoined = false
      } else {
        this.isJoined = true
      }
    }
  },
  /**
   * Determine initial state of loading
   */
  created () {
    const segment = Vue.$pathConverter.getFirstSegment()
    if ('email' in this.charityOfApp && this.charityOfApp.email === segment) {
      this.isLoaded = true
    } else {
      this.isLoaded = false
    }
    this.updateSubscription(this.registeredCharities)
  }
}
</script>
