<template>
    <div>
        <div class="d-flex justify-center">
        <v-avatar
            :size="$vuetify.breakpoint.mobile ? 82 : 130"
            class="black mt-2">
            <v-avatar
            :size="$vuetify.breakpoint.mobile ? 80 : 128"
            :elevation="3" outlined class="white">
            <span v-if="!(user.picture)" class="black--text display-1">
                {{user.name.substring(0, 2)}}</span>
            <v-img v-else  :src="user.picture" />
            </v-avatar>
        </v-avatar>
        </div>
        <v-list class="mt-n2">
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>
                        <span class="d-flex justify-center text-h6 text-uppercase">
                        {{user.name}}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <span class="d-flex justify-center text-subtitle-1 text-uppercase">
                        -{{user.group}}-
                        </span>
                        <span v-if= "user.group === 'charity'"
                        class="d-flex justify-center text-subtitle-1 text-uppercase">
                        {{id}}
                        </span>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>

export default ({
  name: 'ProfileHead',
  props: ['user'],
  computed: {
    id () {
    //   console.log(this.user.userId)
      const front = this.user.userId.split('-')[0]
      return `ID: ${front}`
    }
  }
})
</script>
